
					@import './src/sass/variables';
					@import './src/sass/mixins';
				



























































































.input-group {
	margin-top: $default_padding / 2;
}
